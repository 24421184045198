.About {
    &__content {
        padding: 96px 24px;
        max-width: 1024px;
        margin: 0 auto;
    }

    &__panels {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        position: relative;
        margin: 32px 0;

        &::before {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: 32px;
            overflow: hidden;
            border-bottom-width: 1px;
            border-bottom-style: dashed;
        }
    }

    &__panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(25% - (48px / 4));
    }

    &__icon-wrapper {
        padding: 16px;
        position: relative;
        z-index: 2;
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__box {
        padding: 20px;
        border-width: 1px;
        border-style: solid;
        height: 100%;
    }

    &__icon-box {
        width: 40px;
        height: 40px;
        border-width: 1px;
        border-style: solid;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 800px) {
    .About {
        &__content {
            padding: 72px 32px;
        }
        &__grid {
            display: flex;
            flex-direction: column;

            & > * {
                display: block;
                min-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .About {
        &__content {
            padding: 72px 24px;
        }
    }
}