.Contact {
    &__content {
        padding: 96px 24px;
        max-width: 1024px;
        margin: 0 auto;
    }

    &__method-link {
        width: 100%;
        text-decoration: none;
        color: inherit;
    }

    &__methods {
        display: flex;
        gap: 16px;
    }

    &__method {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 12px;
        cursor: pointer;
        transition: 0.25s;
    }

    &__method-icon {
        padding: 8px;
        width: 40px;
        height: 40px;
        border-radius: 20px !important;
    }
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__row {
        display: flex;
        gap: 16px;
    }

    &__submit-wrapper {
        display: flex;
        justify-content: center;
    }

    &__button {
        svg {
            transition: 0.25s transform;
        }

        &:hover svg {
            transform: translateX(4px);
        }
    }

    &__success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 64px 0;
    }
}

@media only screen and (max-width: 800px) {
    .Contact {
        &__content {
            padding: 72px 32px;
        }
        &__methods {
            flex-direction: column;
        }
    }
    .Form {
        &__row {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 600px) {
    .Contact {
        &__content {
            padding: 72px 24px;
        }
        &__method-link {
            display: inline !important;
            word-break: break-all;
        }
    }
}